import { DocumentReference } from '@angular/fire/firestore';
import { BaseEntity } from '../../shared/models/base-entity';
import { AlunoDoc } from './aluno-doc';

export class Aluno extends BaseEntity {
  contato?: string;
  experimental: boolean;
  ativo: boolean;
  nome: string;
  sobrenome: string;
  userUid?: string;

  constructor(alunoDoc: AlunoDoc, uid: string, ref?: DocumentReference) {
    super(alunoDoc.userUid, alunoDoc.criadoEm, uid, ref);
    this.contato = alunoDoc.contato;
    this.experimental = alunoDoc.experimental;
    this.ativo = alunoDoc.ativo;
    this.nome = alunoDoc.nome;
    this.sobrenome = alunoDoc.sobrenome;
    this.userUid = alunoDoc.userUid;
  }

  override toFireStore() {
    return {};
  }

  nomeCompleto() {
    return `${this.nome} ${this.sobrenome}`;
  }
}
