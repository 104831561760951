import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { User } from '@angular/fire/auth';
import { AlunoService } from '../../../../alunos/services/aluno.service';
import { Aluno } from '../../../../alunos/models/aluno';
import { FirebaseFunctionsService } from '../../../../shared/services/firebase-functions.service';
import { LoadingController, ToastController } from '@ionic/angular';

export interface InformacaoUsuario {
  uid?: string;
  nome?: string;
  sobrenome?: string;

  contato?: string;
  experimental?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InformacaoService {
  constructor(
    private auth: AuthenticationService,
    private alunoService: AlunoService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private firebaseFunction: FirebaseFunctionsService
  ) {}

  public getUserInformation(): Observable<InformacaoUsuario> {
    return this.auth.isAdmin().pipe(
      mergeMap((isAdmin) =>
        isAdmin
          ? this.auth.getUserNotNull().pipe(map(this.toInformacao))
          : this.alunoService.getAlunoUser().pipe(
              filter((aluno) => !!aluno),
              map(this.alunoToInformacao)
            )
      )
    );
  }

  private toInformacao(user: User): InformacaoUsuario {
    const displayNameParts = user.displayName?.split(' ') || ['', ''];

    const firstName = displayNameParts[0] || '';
    const lastName = displayNameParts.length > 1 ? displayNameParts[1] : '';

    return {
      nome: firstName,
      sobrenome: lastName,
    };
  }

  private alunoToInformacao(aluno: Aluno | null): InformacaoUsuario {
    return {
      nome: aluno?.nome,
      sobrenome: aluno?.sobrenome,
    };
  }

  public updateUserInformarion(
    informacao: InformacaoUsuario,
    isAdmin: boolean
  ): Promise<void> {
    if (isAdmin) {
      return this.updateAdmin(informacao);
    } else {
      return this.firebaseFunction.callable<InformacaoUsuario, void>(
        'updateinfoaluno',
        {
          loadingMessage: 'Atualizando...',
          successMessage: 'Informações atualizadas',
        }
      )(informacao);
    }
  }

  public updateAlunoInformarion(informacao: InformacaoUsuario): Promise<void> {
    return this.firebaseFunction.callable<InformacaoUsuario, void>(
      'updateinfoalunoadmin',
      {
        loadingMessage: 'Atualizando...',
        successMessage: 'Informações atualizadas',
      }
    )(informacao);
  }

  private async updateAdmin(informacao: InformacaoUsuario) {
    const loading = await this.loadingController.create({
      message: 'Atualizando...',
    });
    await loading.present();
    await this.auth.updateProfile(informacao.nome, informacao.sobrenome);
    await loading.dismiss();
    const toast = await this.toastController.create({
      message: 'Informações atualizadas',
      color: 'primary',
      duration: 200,
    });
    await toast.present();
  }
}
