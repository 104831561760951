import {DocumentReference} from '@firebase/firestore';
import {DocumentData, QueryDocumentSnapshot, Timestamp} from '@angular/fire/firestore';

export abstract class BaseEntity {

  readonly criadoEm: Timestamp;
  readonly uid: string | undefined;
  readonly ref: DocumentReference | undefined;
  readonly snap?: QueryDocumentSnapshot<DocumentData, DocumentData> 
  criador: string | undefined;

  protected constructor(criador?: string, criadoEm?: Timestamp, uid?: string, ref?: DocumentReference, snap?: QueryDocumentSnapshot<DocumentData, DocumentData>) {
    this.uid = uid;
    this.ref = ref;
    this.criador = criador;
    this.criadoEm = criadoEm === undefined ? Timestamp.now() : criadoEm;
    this.snap = snap;
  }

  public setCriador(criador: string) {
    this.criador = criador;
  }

  public getUid(): string {
    if (this.uid) {
      return this.uid;
    } else {
      throw new Error();
    }
  }

  abstract toFireStore(): any;

}
